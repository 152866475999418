.Systemfunction{
    .banner{
        height:480px;
        background-size: 100% 100%;
        background: linear-gradient(360deg, #F6FAFD 0%, #FDFEFF 100%);
        .bannercenter{
          width:1152px;
          margin: 0 auto;
          height:100%;
          position: relative;
          img{
            display: block;
            height:100%;
            width:100%;
          }
          .bannermain{
            position: absolute;
            top:158px;
            left: 61px;
            .bannermaintop{
                font-size: 40px;
                font-weight: 600;
                color: #040513;
                line-height: 56px;
                margin-bottom: 16px;
            }
            .bannermainmiddle{
                width: 369px;
                font-size: 18px;
                font-weight: 300;
                color: #040513;
                line-height: 28px;
            }
            .bannermainbottom{
              margin-top: 40px;
              display: flex;
              >div{
                cursor: pointer;
              }
              .bannermainbottomleft{
                background-color: #1A5FFC;
                color: #fff;
                width: 116px;
                height: 36px;
                border-radius: 2px;
                margin-right: 17px;
                text-align: center;
                line-height: 36px;
                font-size: 14px;
              }
              .bannermainbottomright{
                width: 116px;
                height: 36px;
                background: #fff;
                border-radius: 2px;
                line-height: 36px;
                font-size: 14px;
                color: #1A5FFC;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    .list{
        .listitemBox{
            background-color: #fff;
            .listitem{
                width:1151px;
                height: 567px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .line{
                    width:111px;
                }
                .listitemmain{
                    flex:1;
                    >img{
                        margin-bottom: 32px;
                    }
                    .title{
                        font-size: 28px;
                        font-weight: 600;
                        color: #040513;
                        line-height: 40px;
                        margin-bottom: 31px;
                    }
                    .minlist{
                        display: flex;
                        align-items: center;
                        height: 24px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #040513;
                        line-height: 24px;
                        margin-bottom: 12px;
                        >i{
                            width: 5px;
                            height: 5px;
                            background: #6BD3C7;
                            border-radius: 50%;
                            margin-right: 10px;
                        }
                        >span{
                            font-weight: 600;
                        }
                    }
                    >p{
                        font-size: 15px;
                        font-weight: 300;
                        color: #040513;
                        line-height: 24px;
                        opacity: .56;
                        margin-top: 3px;
                    }
                    .btn{
                        cursor: pointer;
                        width: 115px;
                        height: 36px;
                        background: rgba($color: #1A5FFC, $alpha: .07);
                        border-radius: 2px;
                        color: #1A5FFC;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 40px;
                    }
                }
            }
        }
        .listitemBox:nth-child(2n){
            background: #FAFCFF;
        }
    }
}