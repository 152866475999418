.type2{
  .tabbarBox{
    width:1152px;
    display: flex;
    justify-content: space-between;
    height:60px;
    align-items: center;
    
    position: absolute;
    top:0;
    left: 0;
    right:0;
    margin: 0 auto;
    z-index: 999;
    >div{
      display: flex;
    }
    .logo{
      display: block;
    }
    .tabbar{
      display: flex;
      align-items: center;
      >div{
        padding: 0 22px;
        font-size: 15px;
        color: #040513;
        text-align: center;
        position:relative;
        line-height: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .menu{
          position: absolute;
          bottom: -223px;
          left: -10px;
          width: 136px;
          height: 212px;
          background: #FFFFFF;
          box-shadow: 0px 3px 8px 0px rgba(6,46,147,0.6);
          border-radius: 2px;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #040513;
          line-height: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 30px 40px;
          >span:hover {
            color: #1A5FFC;
          }
        }
      }
      .changeIndex{
        color: #1A5FFC;
      }
      .changeIndex::after{
        content:' ';
        display: block;
        width: 24px;
        height: 2px;
        background: #1A5FFC;
        position: absolute;
        left: 0;
        bottom: -7px;
        right: 0;
        margin: 0 auto;
      }
    }
    .login{
      cursor: pointer;
      width: 74px;
      height: 32px;
      border-radius: 3px;
      border: 1px solid #1A5FFC;
      justify-content: center;
      line-height: 32px;
      color: #1A5FFC;
      font-size: 14px;
    }
  }
}