.chargingStandard{
    background-color: #FAFCFF;
    .bannerBox{
        background-color: #040B21;
        height:480px;
        .banner{
            position: relative;
            width:1152px;
            margin:0 auto;
            .content{
                position: absolute;
                top:175px;
                left: 0;
                .title{
                    font-size: 40px;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 56px;
                    margin-bottom: 20px;
                }
                .text{
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-weight: 300;
                    color: #FFFFFF;
                    line-height: 28px;
                }
                
            }
        }
    }
    .typelist{
        width: 1152px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        position: relative;
        top:-46px;
        .typelistitem{
            width:271px;
            height:434px;
            padding: 0 24px;
            box-sizing: border-box;
            background-color: #fff;
            box-shadow: 0px 2px 12px 0px rgba(8,78,253,0.05);
            position: relative;
            border-radius: 12px;
            .title{
                
                line-height: 69px;
                font-size: 24px;
                font-weight: 600;
                color: #040513;
                text-align: center;
                border-bottom: 1px solid #04B593;
            }
            .price{
                padding-top: 23px;
                font-size: 44px;
                font-weight: 600;
                color: #040513;
                line-height: 56px;
                text-align: center;
                >span{
                    font-size: 20px;
                    font-weight: 600;
                    color: #040513;
                }
            }
            .user{
                font-size: 16px;
                font-weight: 400;
                color: #040513;
                line-height: 24px;
                text-align: center;
                margin-bottom: 32px;
            }
            >p{
                vertical-align: middle;
                padding-left:20px;
                >img{
                    margin-right: 7px;
                }
                font-size: 13px;
                font-weight: 300;
                color: rgba($color: #040513, $alpha: .7);
                line-height: 30px;
                margin-bottom: 0;
            }
            .btn{
                width: 201px;
                height: 44px;
                background: #04B593;
                border-radius: 2px;
                position: absolute;
                right: 0;
                left: 0;
                bottom:30px;
                margin: 0 auto;
                color: #fff;
                font-size: 14px;
                line-height: 44px;
                text-align: center;
                cursor: pointer;
            }
        }
        .typelistitem:nth-child(2){
            transform: scaleY(1.1);
            .title{
                border-color: #13AEF5;
            }
            .btn{
                background-color: #13AEF5;
            }
        }
        .typelistitem:nth-child(3){
            .title{
                border-color: #1A5FFC;
            }
            .btn{
                background-color: #1A5FFC;
            }
        }
        .typelistitem:nth-child(4){
            text-align: center;
            >img{
                margin: 27px 0 14px;
            }
            p{
                padding-left: 0;
                text-align: center;
            }
            .title{
                border-color: #1A1917;
            }
            .btn{
                color: #FFE8C7;
                background-color: #1A1917;
            }
        }
    }
    .pricescheme{
        width: 1152px;
        margin: 0 auto;
        .title{
            font-size: 28px;
            font-weight: 600;
            color: #040513;
            line-height: 40px;
            margin-bottom: 50px;
        }
    }
    .QA{
        width: 1152px;
        margin: 0 auto;
        .title{
            font-size: 28px;
            font-weight: 600;
            color: #040513;
            line-height: 40px;
            margin-bottom: 50px;
        }
        .mainitem{
            margin-bottom: 54px;
            >div{
                display: flex;
                line-height: 28px;
                font-size: 20px;
                >span{
                    color: #fff;
                    width: 26px;
                    height: 26px;
                    border-radius: 2px;
                    font-weight: 400;
                    line-height: 28px;
                    text-align: center;
                    margin-right: 12px;
                }
            }
            >div:nth-child(1){
                margin-bottom: 24px;
                color: #1A5FFC;
                >span{
                    background: #1A5FFC;
                }
            }
            >div:nth-child(2){
                color: rgba($color: #040513, $alpha: .7);
                >span{
                    background: #040513;
                }
            }
        }
    }
}