.miniappmodel{
   position: fixed;
   top:0;
   left:0;
   width:100%;
   height:100%;
   background-color: rgba($color: #000000, $alpha: .5);
   .miniappmodelmain{
        position: absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        margin: auto;
        width: 648px;
        height: 790px;
        background: #FFFFFF;
        border-radius: 3px;
        text-align: center;
        box-sizing: border-box;
        padding-top: 142px;
        // transform: scale(.7);
        .icon-cha{
            position: absolute;
            top: 30px;
            right: 30px;
            font-size: 44px;
            color: #C7CEE3;
        }
        >p{
            margin-top: 46px;
            font-size: 32px;
            font-weight: 500;
            color: #040513;
            line-height: 45px;
        }
   }
}