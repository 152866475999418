.fixedmodel{
    position: fixed;
    top:50%;
    right:78px;
    width:120px;
    height:400px;
    z-index: 99;
    .close{
        text-align: right;
        .icon-cha{
            cursor: pointer;
            font-size: 30px;
            color: #3A7DFF;
        }
    }
    .main{
        text-align: center;
        width:120px;
        height: 400px;
        border-radius: 120px;
        background: linear-gradient(146deg, #3A7DFF 0%, #105BF1 100%);
        box-sizing: border-box;
        padding-top: 4px;
        .heard{
            width: 112px;
            height: 112px;
            margin-bottom: 12px;
        }
        .code{
            width:80px;
            height:80px;
            margin-bottom: 8px;
        }
        >div{
            font-size: 14px;
            color: #B2CDFF;
            >img{
                width:32px;
                height:32px;
                margin-top: 18px;
            }
        }
        >button{
            width: 96px;
            height: 40px;
            background: #FEFEFE;
            box-shadow: 0px 2px 14px 0px rgba(2,62,182,0.54);
            border-radius: 20px;
            border: none;
            color: #1A5FFC;
            font-size: 16px;
            margin-top: 24px;
        }
    }
}