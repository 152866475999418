.chargingStandard{
    background-color: #FAFCFF;
    .bannerBox{
        background-color: #040B21;
        height:480px;
        .banner{
            position: relative;
            width:1152px;
            margin:0 auto;
            .content{
                position: absolute;
                top:175px;
                left: 0;
                .title{
                    font-size: 40px;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 56px;
                    margin-bottom: 20px;
                }
                .text{
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-weight: 300;
                    color: #FFFFFF;
                    line-height: 28px;
                }
                
            }
        }
    }
    .templatebox{
        width:1152px;
        margin: 0 auto;
        .templatetitle{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 71px 0 32px;
            font-size: 28px;
            font-weight: 600;
            color: #040513;
            line-height: 40px;
            >span{
                font-size: 20px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 300;
                color: rgba($color: #040513, $alpha: .5);
                line-height: 28px;
            }
        }
        .templatetab{
            background-color: #fff;
            box-shadow: 0px 2px 8px 0px rgba(111,143,221,0.08);
            border-radius: 4px;
            padding: 24px;
            display: flex;
            flex-wrap: wrap;
            .templatetabitem{
                color: #040513;
                margin-right: 34px;
                border-radius: 3px;
                margin-bottom: 20px;
                cursor: pointer;
                line-height: 48px;
                font-size: 20px;
                width: 104px;
                text-align: center;
            }
            .changeitem{
                background-color: #1A5FFC;
                color: #fff;
            }
        }
        .templatelist{
            display: flex;
            flex-wrap: wrap;
            padding-top: 84px;
            .templatelistitem{
                width: 348px;
                height: 752px;
                border-radius: 2px;
                border: 4px solid rgba($color: #084EFD, $alpha: .1);
                filter: blur(0px);
                margin-right: 50px;
                margin-bottom: 58px;
                >img{
                    width:100%;
                    height:100%
                }
            }
            .templatelistitem:nth-child(3n){
                margin-right: 0;
            }
        }
    }
    .paginationBox{
        width:1152px;
        margin: 0 auto;
    }
    .pagination{
        width: 418px;
        margin: 0 auto;
        margin-bottom: 91px;
    }
    .QA{
        width: 1152px;
        margin: 0 auto;
        .title{
            font-size: 28px;
            font-weight: 600;
            color: #040513;
            line-height: 40px;
            margin-bottom: 50px;
        }
        .mainitem{
            margin-bottom: 54px;
            >div{
                display: flex;
                line-height: 26px;
                font-size: 20px;
                >span{
                    color: #fff;
                    width: 26px;
                    height: 26px;
                    border-radius: 2px;
                    font-weight: 400;
                    line-height: 26px;
                    text-align: center;
                    margin-right: 12px;
                }
            }
            >div:nth-child(1){
                margin-bottom: 24px;
                color: #1A5FFC;
                >span{
                    background: #1A5FFC;
                }
            }
            >div:nth-child(2){
                color: rgba($color: #040513, $alpha: .7);
                >span{
                    background: #040513;
                }
            }
        }
    }
}