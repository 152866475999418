.aboutus{
    .bannerBox{
        height:622px;
        background-color: #040B21;
        .banner{
            
            position: relative;
            width:1152px;
            margin: auto;
            .content{
                position: absolute;
                top:175px;
                left: 0;
                .title{
                    font-size: 40px;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 56px;
                    margin-bottom: 20px;
                }
                .text{
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-weight: 300;
                    color: #FFFFFF;
                    line-height: 28px;
                }
                
            }
        }
    }
    .contentBox{
        background-color: #fff;
        .content{
            width: 1152px;
            margin: 0 auto;
            .title{
                padding-top:90px;
                font-size: 28px;
                font-weight: 600;
                color: #040513;
                line-height: 28px;
                margin-bottom: 60px;
                >img{
                    margin-right: 3px;
                }
            }
            >p{
                font-size: 16px;
                font-weight: 300;
                color: #040513;
                line-height: 26px;
            }
        }
        .list{
            padding-top: 40px;
            display: flex;
            justify-content: space-between;
            .listitem{
                margin-right: 15px;
                p{
                    padding: 20px 5px;
                    font-size: 16px;
                    font-weight: 300;
                    color: #040513;
                    line-height: 26px;
                }
            }
            
        }
    }
    .box{
        background:url('./img/odfsgjifgkf.png');
        height:481px;
        .boxcenter{
            width:1152px;
            margin: 0 auto;
            .title{
                padding-top:90px;
                font-size: 28px;
                font-weight: 600;
                color: #fff;
                line-height: 28px;
                margin-bottom: 50px;
                >img{
                    margin-right: 3px;
                }
            }
            .text{
                font-size: 18px;
                font-weight: 300;
                color: #FFFFFF;
                line-height: 28px;
            }
        }
    }
    .Special{
        width:1152px;
        margin: 0 auto;
        .title{
            padding-top:90px;
            font-size: 28px;
            font-weight: 600;
            color: #040513;
            line-height: 28px;
            margin-bottom: 60px;
            >img{
                margin-right: 3px;
            }
        }
        .list{
            display: flex;
            flex-wrap: wrap;
            .listitem{
                display: flex;
                align-items: center;
                margin-bottom: 24px;
                >img{
                    margin-right: 20px;
                }
                .text{
                    width: 240px;
                    font-size: 16px;
                    font-weight: 300;
                    color: #040513;
                    line-height: 26px;
                }
            }
        }
    }
    .bottomBox{
        background-image: url('./img/hjghjghjkoghj.png');
        height: 623px;
        .bottom{
            width: 1152px;
            height: 100%;
            margin: 0 auto;
            position: relative;
            .title{
                padding-top:90px;
                font-size: 28px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 28px;
                margin-bottom: 150px;
                >img{
                    margin-right: 3px;
                }
            }
            >p{
                width: 458px;
                font-size: 18px;
                font-weight: 300;
                color: #FFFFFF;
                line-height: 32px;
            }
            .contact{
                width: 100%;
                position: absolute;
                bottom: 78px;
                left: 0;
                display: flex;
                justify-content: space-between;
                color: #fff;
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
            }
        }
    }
}
